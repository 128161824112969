@media print {
    #mainmenu {
        display: none;
        z-index: 2;
    }

    header .container {
        height: auto;
        padding-bottom: 0;
    }

    header,
    #main,
    footer {
        display: block;
        height: auto !important;
    }

    footer {
        .firstRow {
            .logo {
                display: none;
            }
        }

        .footerContainer {
            margin-top: 0;
        }

    }

    section#main>.inner {
        padding: 50px 0;
    }

}
